import { GraphQLClient } from 'graphql-request';
import { mergeAll } from 'ramda'

if (true) {
  require('cross-fetch/polyfill');
}

export default (ctx, inject) => {
  const baseOptions = {"includeNodeModules":true,"useFetchPolyfill":true,"options":{"method":"POST"},"clients":{"api_v1":{"endpoint":"https:\u002F\u002Fapi.rabotut.ru\u002Fv2\u002Fquery"},"api_v2":{"endpoint":"https:\u002F\u002Fapi.rabotut.ru\u002Fv2\u002Fquery"},"api_server":{"endpoint":"http:\u002F\u002Frabotut-api-core-service\u002Fquery"},"api_server_content":{"endpoint":"http:\u002F\u002Frabotut-content-app-service"},"api_content":{"endpoint":"https:\u002F\u002Fapi.rabotut.ru\u002Fv2\u002Fcontent\u002Fquery"},"api_gql_server_content":{"endpoint":"http:\u002F\u002Frabotut-content-app-service\u002Fquery"}}};

  const graphClients = Object.entries(baseOptions.clients)
    .reduce((clients, [clientName, config]) => {
      const runtimeConfig = (
        ctx.$config
        && ctx.$config.graphql
        && ctx.$config.graphql.clients
        && ctx.$config.graphql.clients[clientName]
      ) || {};

      const clientConfig = mergeAll([baseOptions, config, runtimeConfig])

      return {
        ...clients,
        [clientName]: new GraphQLClient(clientConfig.endpoint, clientConfig.options),
      };
    }, {});

  ctx.$graphql = graphClients;
  inject('graphql', graphClients);
};
