export const YANDEX_VERIFICATION_LIST = {
  chelyabinsk: 'fa3a62614a59024c',
  ekaterinburg: '57fa2a1ac059456f',
  kazan: 'd1065ee5cf668982',
  nn: '2291e218e0ed42cf',
  spb: 'b50484297ef6056b',
  rostov: 'f294fbe8faca53aa',
  arkhangelsk: 'cef690b662c15bde',
  astrahan: 'af7c09f45b870570',
  barnaul: '4222f6a16f39aa86',
  belgorod: '51c95da4ed40ba14',
  cheboksari: 'd721ace87c74897e',
  cherkessk: 'bc1b5360cb4dde48',
  habarovsk: '53c0b05a977165f3',
  irkutsk: 'aff880fc3d161e1c',
  ivanovo: 'b5b684fc3f6f409a',
  izhevsk: '16d99f3d0acca850',
  kaluga: '71d0b00364091848',
  kemerovo: '3abc7624f03b0c6c',
  kirov: 'f06b40a9e9a62044',
  krasnodar: '57069eaa85a7060c',
  krasnoyarsk: '16dd92680602ee2f',
  kurgan: '2693f85566b42484',
  novosibirsk: '1356e2fd6defa215',
  samara: '275eb80f9f0ce310',
  saratov: '6467205872cd666a',
  tula: 'b9493b052d07690c',
}

export const GOOGLE_VERIFICATION_LIST = {
  kaluga: '71d0b00364091848',
}
